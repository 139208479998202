<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <suppliers></suppliers>
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import Suppliers from "@/components/app/supplier/Suppliers";
    export default {
        name: 'Suppliers_view',
        data: function(){
            return {
                pageName: 'Suppliers',
                segments: [
                    { link: false, text: 'Suppliers' },
                ],
            }
        },
        props: {},
        components: {
            Suppliers,
            BreadCrumb
        },
        computed: {

        },

    }
</script>

<style scoped>

</style>
